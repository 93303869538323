<template>
    <section class="baseInfo">
        <div style="border: 1px solid #bce8f1;border-radius: 2px;">
            <div style="color: #3a87ad;background-color: #d9edf7;padding: 10px 15px;border-bottom: 1px solid #bce8f1;border-radius: 2px 2px 0 0;">用户资料</div>
            <div style="padding: 15px;color: #717171;font-size: 13px">
                <div>
                    承租人类型: <span>{{tenantData.type === '法人' ? '企业' : tenantData.type}}</span>
                </div>
                <div>
                    姓名: <span>{{tenantData.name}}</span>
                </div>
                <div>
                    性别: <span>{{tenantData.sex?tenantData.sex:"女"}}</span>
                </div>
                <div>
                    手机号: <span>{{tenantData.tel1}}</span>
                </div>
                <div>
                    证件号码: <span>{{tenantData.idcard}}</span>
                </div>
                <div>
                    工作单位: <span>{{tenantData.workInfo?tenantData.workInfo:"暂无数据"}}</span>
                </div>
                <!--<div>
                    电子邮箱：<span>1194887163@qq.com</span>
                </div>-->
            </div>
        </div>
        <div class="table-title" style="margin: 10px 0;">相关房源</div>
        <r-e-table ref="communityTableRef1" :dataRequest="getLeasorContractsTableData" :columns="relatedHousingColumns"
                   :height="500" :query="formSearch">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
    import {relatedHousingColumns} from "@/views/rental-management/tenant-management/data";
    import {getLeasorContracts} from "@/api/tenant-management";
    export default {
        name: "baseInfo",
        props: {
            tenantData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                relatedHousingColumns:relatedHousingColumns(this),
                formSearch: {

                }
            };
        },
        components:{  },
        async created() {

        },
        methods: {
            getLeasorContractsTableData(params) {
                const { uuid } = this.tenantData;
                params.uuid = uuid;
                return getLeasorContracts(params);
            },
        }
    };
</script>

<style scoped>

</style>
